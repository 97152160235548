body {
  font-size: 14px !important;
  /* overflow-x: hidden !important; */
  font-family: "Nunito Sans", sans-serif, sans-serif !important;
  color: #1F2F3E !important;
  background-color: #ebebeb !important;
}

code {
  font-family: "Nunito Sans", sans-serif, sans-serif !important;
}

::-webkit-scrollbar {
  width: 4px;
  height:4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.swal2-container {
  z-index: 300000 !important;
}

a:hover * {
  color: 'red' !important
}

.text-ellipse {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}


.light-grey{

    color: #25324B;

}

.font-18{

  font-size: 18px;
}

.register-color{

    background-color: #5235E8;

}

.dream-job-color{
    color: rgb(236, 7, 7);
}

.dream-job-font{
    font-size: 48px;
}
